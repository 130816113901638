import React from 'react'
import BaseComponent from './BaseComponent'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Footer from './Footer'
import { NavLink } from 'react-router-dom'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { login: "", pass: "", password: "", newPassword: "", code: "", loginError: false, codeError: false, passError: false, passwordError: false, newPasswordError: false, anim: false, step: "" }
    this._bind('handleInputChange', 'checkLogin', 'checkCode', 'processLogin', 'processEmail', 'processReset', 'onEnterPressed')
  }

  componentDidMount() {
  	document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)

    if (document.getElementById('login')) document.getElementById('login').focus();
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  handleInputChange(event) {
  	const target = event.target
  	let value = target.type === 'checkbox' ? target.checked : target.value
  	let name = target.name

  	this.setState({
  	  [name]: value
  	})

  	if (name === "login" && name !== "") {
      this.setState({ loginError: false })
    }

    if (name === "code" && name !== "") {
      this.setState({ codeError: false })
    }

  	if (name === "pass" && name !== "") {
      this.setState({ passError: false })
    }

    if (name === "password" && name !== "") {
      this.setState({ passwordError: false })
    }

    if (name === "newPassword" && name !== "") {
      this.setState({ newPasswordError: false })
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validatePassword(pass) {
    var re = /^\d{4,30}$/;
    return re.test(pass);
  }

  validateCode(code) {
    var re = /^\d{6,6}$/;
    return re.test(code);
  }

  checkLogin() {
    if (this.state.login === "" || !this.validateEmail(this.state.login)) {
      document.getElementById('login').focus()
      this.setState({ loginError: true })
    }

    if (this.state.login !== "" && this.validateEmail(this.state.login)) {
      this.processEmail()
    }
  }

  checkCode() {
    if (this.state.code === "" || !this.validateCode(this.state.code)) {
      document.getElementById('code').focus()
      this.setState({ codeError: true })
    } 
    if (this.state.password === "" || !this.validatePassword(this.state.password)) {
      if(this.state.code !== "" && this.validateCode(this.state.code)) {
        document.getElementById('password').focus()
      } 
      this.setState({ passwordError: true })
    } 
    if (this.state.newPassword !== this.state.password) {
      document.getElementById('newPassword').focus()
      this.setState({ newPasswordError: true })
    } 

    if ((this.state.code !== "" && this.validateCode(this.state.code)) && (this.state.password !== "" && this.validatePassword(this.state.password)) && (this.state.newPassword !== "" && this.validatePassword(this.state.newPassword)) && this.state.password === this.state.newPassword) {
      this.processReset()
    }
  }

  processEmail() {
    let data  = {email: this.state.login}

    fetch(this._apiBase + "account/resetEmail", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (result.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Kod wysłano na podany adres email."}}), true);
        this.setState({ step: "code" })
        window.gtag('event', 'start_reset_wysylakod')
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        this.setState({ loginError: true })
        window.gtag('event', 'start_reset_wysylakod_error')
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
        this.setState({ loginError: true })
        window.gtag('event', 'start_reset_wysylakod_error')
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      window.gtag('event', 'start_reset_wysylakod_error')
    })

    // if (this.state.login === "test@test.pl") {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Kod wysłano na podany adres email."}}), true);
    //   this.setState({ step: "code" })
    // } else {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Niepoprawny adres email."}}), true);
    //   this.setState({ loginError: true })
    // }
  }

  processReset() {
    let data  = {email: this.state.login, code: this.state.code, password: this.state.newPassword}

    fetch(this._apiBase + "account/passReset", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zresetowano hasło."}}), true);
        this.props.history.replace("/login")
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
        this.setState({ codeError: true })
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
        this.setState({ codeError: true })
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })

    // if (this.state.code === "111111") {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zresetowano hasło."}}), true);
    //   this.props.history.replace("/login")
    // } else {
    //   window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Nie udało się zresetować hasła."}}), true);
    //   this.setState({ codeError: true })
    // }
  }

  processLogin() {
  	if (this.state.login === "admin" && this.state.pass === "admin") {
  		this.props.loginSuccess()
  	} else {
  		this.setState({ login: "", pass: "" })
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Niepoprawne dane logowania."}}), true);
  		document.getElementById('login').focus()
  	}
   //  let data  = {"type" : "login", "params" : { user: this.state.login, password: this.state.pass }, "session_id" : this.props.sessionId}
   //  fetch("/api/app.php", {
   //    method: "POST",
   //    body: JSON.stringify(data)
   //  })
   //  .then((response) => {
   //      return response.json()
   //  })
   //  .then((result) => {
   //    if (result.error) {
   //      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.error}}), true);
   //    } else {
   //      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zalogowano."}}), true);
   //    }
   //    this.sessionId = result.session_id
   //      if (result.logged === true) {
   //        this.props.loginSuccess()
   //      } else {
   //        this.setState({ error: true, login: "", pass: "" })
   //        document.getElementById('login').focus()
   //      }
   //  }, (error) => {   
   //    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Coś poszło nie tak."}}), true);
   //  })
  }

  render() {
    const page = this.props.data.loginPage

    let step = ""

    switch(this.state.step) {
      case 'code':
        step = (
          <div className={ this.state.anim ? "login-box show" : "login-box" }>
            <div className="inputs">
              <h3>{ page.codeTitle }</h3>
              <div className="input">
                <label htmlFor="code">{ page.code }</label>
                <input type="text" name="code" id="code" value={ this.state.code } className={ this.state.codeError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
                { this.state.codeError ? <p className="error">{ page.codeError }</p> : null }
              </div>
              <div className="input">
                <label htmlFor="password">{ page.newPassword }</label>
                <input type="password" name="password" id="password" value={ this.state.password } className={ this.state.passwordError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
                { this.state.passwordError ? <p className="error">{ page.newPasswordError }</p> : null }
              </div>
              <div className="input">
                <label htmlFor="newPassword">{ page.repeatPassword }</label>
                <input type="password" name="newPassword" id="newPassword" value={ this.state.newPassword } className={ this.state.newPasswordError ? "input-error" : "" } onChange={ this.handleInputChange } required/>
                { this.state.newPasswordError ? <p className="error">{ page.repeatPasswordError }</p> : null }
              </div>
              <div className="btn-holder">
                <button className="btn yellow" id="button" onClick={ this.checkCode }><span>{ page.btnCode }</span></button>
              </div>
              <NavLink className="remind" to={ this.props.lang === "en" ? "/en/login" : "/login" }>{ page.backToLogin }</NavLink>
            </div>
            <div className="separator"></div>
            <div className="benefits">
              <h3>{ page.benefitsTitle }</h3>
              <ul>
                { page.benefits.map((item, i) => {
                  return (
                    <li key={ i }>{ item }</li>
                  )
                }) }
              </ul>
              <p className="access" dangerouslySetInnerHTML={{ __html: page.access }}></p>
            </div>
          </div>
        )
      break;
      default:
        step = (
          <div className={ this.state.anim ? "login-box show" : "login-box" }>
            <div className="inputs">
              <h3>{ page.remindTitle }</h3>
              <div className="input">
                <label htmlFor="login">{ page.remindEmail }</label>
                <input type="email" name="login" id="login" className={ this.state.loginError ? "input-error" : "" } value={ this.state.login } onChange={ this.handleInputChange } required/>
                { this.state.loginError ? <p className="error">{ page.remindEmailError }</p> : null }
              </div>
              <div className="btn-holder">
                <button className="btn yellow" id="button" onClick={ this.checkLogin }><span>{ page.btnRemind }</span></button>
              </div>
              <NavLink className="remind" to={ this.props.lang === "en" ? "/en/login" : "/login" }>{ page.backToLogin }</NavLink>
            </div>
            <div className="separator"></div>
            <div className="benefits">
              <h3>{ page.benefitsTitle }</h3>
              <ul>
                { page.benefits.map((item, i) => {
                  return (
                    <li key={ i }>{ item }</li>
                  )
                }) }
              </ul>
              <p className="access" dangerouslySetInnerHTML={{ __html: page.access }}></p>
            </div>
          </div>
        )
      break;
    }

    return (
      <main>
        <section className="login">
          <TransitionGroup>
            <CSSTransition key={ this.props.location.pathname } classNames="page" timeout={{ enter: 500, exit: 250 }} >
              { step }
            </CSSTransition>
          </TransitionGroup>
        </section>
        <Footer/>
      </main>
    )
  }
}
