import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import _ from 'lodash'
import Modal from './Modal'
import Select from './Select'
import SelectProject from './SelectProject'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';
import { Map, TileLayer, Marker, Polyline } from 'react-leaflet'
import Leaflet from 'leaflet'
//import Routing from "./RoutingMachine";
registerLocale('pl', pl)

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    let startMonth = new Date()
    startMonth.setMonth(startMonth.getMonth(), 1)
    let endMonth = new Date()
    endMonth.setMonth(endMonth.getMonth()+1, 0)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      report: { active: true }, reportS: [], itemPopup: false, id: null, rewrite: {},
      alerts: [],
      courses: [],
      warns: [],
      orderCounter: 0,
      orderTotalAmount: 0,
      startMonth: startMonth,
      endMonth: endMonth,
      data: [],
      preloader: true,
      generatePopup: false,
      printPreloader: false,
      projects: [],
      projectsArray: [],
      departments: [],
      departmentsArray: [],
      vouchers: [],
      selectedProject: { id: null },
      selectedDepartment: { id: null },
      selectedVoucher: { id: null },
      name: "",
      mapPopup: false,
      mapPositions: []
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'changeValue', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'handleInputChange',
      'deleteSuccess',
      'update',
      'nextMonth',
      'prevMonth',
      'shortFetchXLS',
      'getReport',
      'editComment',
      'editProject',
      'getProjects',
      'successGetProjects',
      'clearFilters',
      'getReportData',
      'showMap'
    )

    this.months = [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień"
    ]

    if (this.props.lang !== "pl") {
      this.months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    }

    this.endpoint = "report"

    this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id)
    //this.getProjects()
    this.getReportData()
    // this.getMPK()
    // this.getVouchers()
  
    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ preloader: true })
    this.setState({ report: {}, itemPopup: false, id: null, rewrite: {} })
    this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id)
    //this.getProjects()
    this.getReportData()
    // this.getMPK()
    // this.getVouchers()
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/reports/r" : "/en/reports/r")
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  shortFetch(method, endpoint, data, success, failed) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        if (failed) {
          failed()
        }
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        if (failed) {
          failed()
        }
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
    }, (error) => {
      if (failed) {
        failed()
      }
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  shortFetchXLS(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else if(response.status === 204) {
        this.noContent = true
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Brak danych dla raportu" }}), true)
      } else {
        this.noContent = false
        return response.blob()
      }
    })
    .then((result) => {
      if (!this.noContent) {
        var url = window.URL.createObjectURL(result);
        var a = document.createElement('a');
        a.href = url;
        a.download = "report.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove(); 
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.exportSuccessfully }}), true)
      }
      setTimeout(() => { this.setState({ preloader: false }) }, 300)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
      setTimeout(() => { this.setState({ preloader: false }) }, 200)
    })
  }

  getProjects() {
    this.shortFetch("GET", "projects/short", null, this.successGetProjects)
  }

  getReportData() {
    this.shortFetch("GET", "report/form", null, (data) => {
      let projects = []
      if(data.result.projects) {
        for (var i = 0; i < Object.entries(data.result.projects).length; i++) {
          let item = { id: Object.entries(data.result.projects)[i][0], name: Object.entries(data.result.projects)[i][1] }
          projects.push(item)
        }
      }
      let vouchers = []
      if(data.result.vouchers) {
        for (var j = 0; j < Object.entries(data.result.vouchers).length; j++) {
          let item = { id: Object.entries(data.result.vouchers)[j][0], name: Object.entries(data.result.vouchers)[j][1] }
          vouchers.push(item)
        }
      }
      let departments = []
      if(data.result.departments) {
        for (var k = 0; k < Object.entries(data.result.departments).length; k++) {
          let item = { id: Object.entries(data.result.departments)[k][0], name: Object.entries(data.result.departments)[k][1] }
          departments.push(item)
        }
      }
      this.setState({ projects: projects, projectsArray: projects, vouchers: vouchers, vouchersArray: vouchers, departments: departments, departmentsArray: departments })
    })
  }

  successGetProjects(data) {    
    let projects = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      projects.push(item)
    }
    this.setState({ projects: projects, projectsArray: projects })
  }

  handleInputChange(event) {    
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    this.setState({
      [name]: value
    })
  }

  listItems(dateFrom, dateTo, name, voucher, department, project) {
    this.setState({ preloader: true, data: [] })
    this.shortFetch("POST", this.endpoint+"", { dateFrom: dateFrom, dateTo: dateTo, name: name, voucherId: voucher, departmentId: department, projectId: project }, this.successListItems, () => {
      this.setState({ data: [], preloader: false })
    })
  }

  successListItems(data) {    
    if (data.result.hash) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.shortFetch("POST", this.endpoint+"", { hash: data.result.hash }, this.successListItems)
      }, 1000)
    } else {
      this.setState({ data: data.result.courses || [], alerts: data.result.alerts || [], warns: data.result.warns || [], itemPopup: false, orderCounter: data.result.orderCounter || 0, orderTotalAmount: data.result.orderTotalAmount || 0 })
      this.setState({ preloader: false })
    }
  }

  addItem() {
    this.setState({ report: { active: true }, itemPopup: true, action: "add" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    this.setState({ report: data.result.report, itemPopup: true, action: "edit" })
  }

  saveItem() {
    if (this.state.id) {
      this.shortFetch("POST", this.endpoint+"/edit", { id: this.state.id, archive: this.state.archival, comment: this.state.comment || null, projectId: this.state.projId || null }, () => { 
        this.setState({ itemPopup: false, action: "", comment: null, passengerNotes: null, projId: null })
        this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
      })
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.rewriteItem()
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id)
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeValue(item) {
    this.setState({ rewrite: item })
  }

  nextMonth() {
    let start = this.state.startMonth
    start.setMonth(start.getMonth()+1)
    let end = this.state.endMonth
    end.setMonth(start.getMonth()+1, 0)
    end.setYear(start.getFullYear())
    this.setState({ startMonth: start, endMonth: end })
    this.update()
  }

  prevMonth() {
    let start = this.state.startMonth
    start.setMonth(start.getMonth()-1)
    let end = this.state.endMonth
    end.setMonth(start.getMonth()+1, 0)
    end.setYear(start.getFullYear())
    this.setState({ startMonth: start, endMonth: end })
    this.update()
  }

  getReport() {
    this.setState({ preloader: true })
    this.shortFetchXLS("POST", "report/export", { 
      dateFrom: this.state.startMonth.getTime(), 
      dateTo: this.state.endMonth.getTime(),
      name: this.state.name || null,
      projectId: this.state.selectedProject.id || null,
      voucherId: this.state.selectedVoucher.id || null,
      departmentId: this.state.selectedDepartment.id || null
    }, (data) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.generateSuccessfully }}), true)
      setTimeout(() => { this.setState({ preloader: false }) }, 200)
    })
  }

  clearFilters() {
    let startMonth = new Date()
    startMonth.setMonth(startMonth.getMonth(), 1)
    let endMonth = new Date()
    endMonth.setMonth(endMonth.getMonth()+1, 0)
    this.setState({ name: "", selectedProject: {}, selectedDepartment: {}, selectedVoucher: {}, startMonth: startMonth, endMonth: endMonth })
    setTimeout(() => { window.dispatchEvent(new CustomEvent("UPDATE", { 'detail': {}}), true) }, 200)
  }

  editComment(id, archival, projId, val) {
    this.setState({ itemPopup: true, action: "edit_comment", id: id, archival: archival, projId: projId, comment: val })
  }

  editProject(id, archival, comment, projId) {
    let report = this.state.report
    report.projectId = projId
    this.setState({ itemPopup: true, action: "edit_project", id: id, archival: archival, projId: projId, comment: comment })
    // console.log(id, archival)
  }

  showMap(item) {
    //console.log(item)
    this.shortFetch("POST", this.endpoint+"/route", { id: item.original.id, archive: item.original.archival }, (result) => { 
      if (result.answer === "OK") {
        let mapP = result.result.positions
        let mapPositions = []

        for (var i = 0; i < mapP.length; i++) {
          mapPositions.push([mapP[i].lat, mapP[i].lon])
        }

        this.setState({ mapPositions: mapPositions, mapPopup: true })
      }
    })
  }

  saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };

  render () {
    const thisMonth = new Date()
    const page = this.props.data.manage

    let Popup = null
    

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.report.confirmRewrite : page.report.confirm }</strong></p>
            { this.state.action === "delete_rewrite" ? <div className="input">
              <label>{ page.report.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={"Wpisz..."} id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else if(this.state.action === "edit_comment") {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ page.report.edit }</h3>
            <div className="input">
              <label htmlFor="comment">{ page.report.comment }</label>
              <textarea name="comment" id="comment" value={ this.state.comment } onChange={ this.handleInputChange } required></textarea>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    } else if(this.state.action === "edit_notes") {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ page.report.edit }</h3>
            <div className="input">
              <label htmlFor="passengerNotes">{ page.report.passengerNotes }</label>
              <textarea name="passengerNotes" id="passengerNotes" value={ this.state.passengerNotes } onChange={ this.handleInputChange } required></textarea>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    } else if(this.state.action === "edit_project") {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ page.report.edit }</h3>
            <div className="input">
              <label>{ page.report.projectId }</label>
              <SelectProject noResults={ this.props.data.table.noResults } proj={true} selected={ this.state.projId } data={this.state.projectsArray} filters={['name']} placeholder={ page.type } changeValue={ (item) => {
                let report = this.state.report
                report.projectId = item.id
                this.setState({ project: item, report: report, projId: item.id })
              } } id="projectId"/>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    } else {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label htmlFor="name">{ page.report.name }</label>
              <input type="text" name="name" id="name" value={ this.state.report.name } onChange={ this.handleInputChange.bind(null, "report") } required/>
            </div>
            <div className="input">
              <label htmlFor="description">{ page.report.description }</label>
              <textarea type="number" name="description" id="description" value={ this.state.report.description } onChange={ this.handleInputChange.bind(null, "report") } required></textarea>
            </div>
            <div className="input">
              <div className="check-box"><input name="active" id="cb2" type="checkbox" checked={ this.state.report.active !== undefined ? this.state.report.active : true } onChange={ this.handleInputChange.bind(null, "report") }/><label htmlFor="cb2">Czy aktywny?</label></div>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    let selectedFilters = []

    let showFilters = this.state.name || this.state.selectedVoucher.id || this.state.selectedProject.id || this.state.selectedDepartment.id ? true : false

    if (this.state.name) {
      let item = page.report.nameLastname
      selectedFilters.push(item)
    }

    if (this.state.selectedDepartment.id) {
      let item = "MPK"
      selectedFilters.push(item)
    }

    if (this.state.selectedProject.id) {
      let item = page.report.projectId
      selectedFilters.push(item)
    }

    if (this.state.selectedVoucher.id) {
      let item = page.report.voucher
      selectedFilters.push(item)
    }

    let position = this.state.mapPositions.length > 0 ? [this.state.mapPositions[0][0], this.state.mapPositions[0][1]] : [0, 0]

    let mapModal = (
      <div className="password-modal map">
          <h3>{ page.report.route }</h3>
          { this.state.mapPositions.length > 0 ? <div className="inputs map">
            <Map ref={this.saveMap} whenReady={ (map) => {
              //console.log("map loaded")
            } } center={position} animate={true} bounds={ Leaflet.latLngBounds(this.state.mapPositions) }>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {this.state.mapPositions.length > 0 && this.state.mapPositions[0][0] ? <Marker position={[this.state.mapPositions[0][0], this.state.mapPositions[0][1]]}></Marker> : null}
              {this.state.mapPositions.length > 0 && this.state.mapPositions[this.state.mapPositions.length-1][0] ? <Marker position={[this.state.mapPositions[this.state.mapPositions.length-1][0], this.state.mapPositions[this.state.mapPositions.length-1][1]]}></Marker> : null}
              {this.state.isMapInit && <Polyline color="#f44" positions={this.state.mapPositions} />}
            </Map>
          </div> : <div className="inputs">
          <h4>Brak możliwości automatycznego wyznaczenia kursu - skontaktuj się opiekunem</h4>
        </div> }
        <div className="buttons">
          <button className="btn transparent small" id="button" onClick={ () => { this.setState({ mapPopup: false, mapPositions: [] }) } }><span>{ this.props.data.header.close }</span></button>
        </div>
      </div>
    )
    console.log(this.props.user.userType)
    return (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/reports/r' : '/en/reports/r' }>{ page.reportsName }</NavLink>
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <span className="sep">|</span> }
            { this.props.user.userType === "USER" && this.props.user.cardUser === false ? null : <NavLink to={ this.props.lang === "pl" ? '/reports/i' : '/en/reports/i' }>{ page.invoicesName }</NavLink> }
            { this.props.user.userType === "SUPER" ? (<><span className="sep">|</span> <NavLink to={ this.props.lang === "pl" ? '/reports/cr' : '/en/reports/cr' }>{ page.cyclicalReports.tabName }</NavLink></>) : null}
          </div>
          <ul className="alerts">
            { this.state.alerts.map((item, i) => {
              return <li key={ i } className="alert"><img src="/assets/img/alert-red.svg" alt=""/><span>{ item }</span></li>
            }) }
            { this.state.warns.map((item, i) => {
              return <li key={ i } className="warn"><img src="/assets/img/alert-orange.svg" alt=""/><span>{ item }</span></li>
            }) }
          </ul>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.report.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small violet" onClick={ () => { this.setState({ generatePopup: true }); window.gtag('event', "raportyfaktury_wybierzfiltrowanie") } }><span>{ page.report.filters }</span></button>
                <button className="btn small green" onClick={ () => { this.getReport(); window.gtag('event', "raportyfaktury_pobierzraport") } }><span>{ page.report.download }</span></button>
              </div>
            </div>
            <div className="select-date">
              <p>{ page.report.filterMonth } </p>
              <div className="dd">
                <span className="prev" onClick={ this.prevMonth }></span>
                <p>
                  <strong>{ this.months[this.state.startMonth.getMonth()] + " " + this.state.startMonth.getFullYear() }</strong>
                  { this.state.startMonth.getMonth() !== this.state.endMonth.getMonth() ? <strong> - { this.months[this.state.endMonth.getMonth()] + " " + this.state.endMonth.getFullYear() }</strong> : null }
                </p>
                { this.state.startMonth.getMonth() >= thisMonth.getMonth() && this.state.startMonth.getFullYear() >= thisMonth.getFullYear() ? null : <span className="next" onClick={ this.nextMonth }></span> }
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <p>Zrealizowanych kursów: <strong>{ this.state.orderCounter }</strong></p>
              <p>Koszt netto: <strong>{ this.state.orderTotalAmount + " zł" }</strong></p>
            </div>
            { showFilters ? <div className="active-filters-list" style={{ position: "relative", top: "30px" }}>
              { this.props.lang === "pl" ? "Filtry:" : "Filters:" }
              <div>
                { selectedFilters.map((item, i) => {
                  return <span className="fil" key={ i }> { item }</span>
                }) }
              </div>

              { /*eslint-disable-next-line*/ }
              <a onClick={ this.clearFilters }>{ page.report.clearFilters }</a>
            </div> : null }
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={ this.props.user.userType === "SUPER" ? [
              {
                Header: page.report.passengerName,
                accessor: "pname",
                minWidth: 95,
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { this.setState({ name: row.value }); this.listItems(this.state.startMonth, this.state.endMonth, row.value, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.voucher,
                accessor: "voucherName",
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.vouchers, { name: row.value })[0]; if(item) { this.setState({ selectedVoucher: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, item ? item.id : null, this.state.selectedDepartment.id, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.projectId,
                accessor: "projectName",
                minWidth: 100,
                Cell: row => <> <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.projects, { name: row.value })[0]; if(item) { this.setState({ selectedProject: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, item ? item.id : null) } }>{ row.value }</span>
                <div className="options">
                  <span onClick={ () => { this.editProject(row.original.id, row.original.archival, row.original.passengerNotes, row.original.projectId) } }><img src="/assets/img/edit-icon.png" alt=""/>{ row.value ? this.props.data.ordered.change : page.report.addProject }</span>
                </div>
                </>
              },
              {
                Header: page.report.mpk,
                accessor: "departmentName",
                minWidth: 100,
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.departments, { name: row.value })[0]; if(item) { this.setState({ selectedDepartment: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, item ? item.id : null, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.invoiceDate,
                accessor: "invoiceBusinessDate",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.orderDate,
                accessor: "makeOrderTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.endDate,
                accessor: "finishTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.startAddress,
                Cell: row => ( <span>{ row.original.street + " " + row.original.buildingNr + ", " + row.original.city }</span> ),
                minWidth: 110
              },
              {
                Header: page.report.endAddress,
                accessor: "destAddress",
                minWidth: 110
              },
              {
                Header: page.report.route,
                minWidth: 110,
                Cell: row => <div className="options">
                  <span onClick={ () => { this.showMap(row) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.showMap }</span>
                </div>
              },
              {
                Header: page.report.rideComment,
                accessor: "phint",
                show: this.props.user.userType !== "USER" ? true : false,
                minWidth:130
              },
              {
                Header: page.report.co2Emission,
                accessor: "co2Emission",
                show: this.props.user.userType !== "USER" ? true : false,
                minWidth: 85
              },
              {
                Header: page.report.kilometers,
                accessor: "kmTraveled",
                show: this.props.user.userType === "SUPER" ? true : false,
                minWidth: 55
              },
              {
                Header: page.report.fees,
                accessor: "charge",
                minWidth: 65
              },
              {
                Header: page.report.surcharge,
                accessor: "surcharge",
                minWidth: 115
              },
              {
                Header: page.report.surchargeName,
                accessor: "surchargeName",
                Cell: row => ( <span>{ row.value ? (row.value === "Kurs zamówiony emailem" ? page.report.surchargeEmail : page.report.surchargePhone) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.netValue,
                accessor: "amountNetto",
                Cell: row => ( <span>{ row.value }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.guaranteedPrice,
                accessor: "guaranteedPrice",
                headerClassName: "no-search",
                Cell: row => (<div>{ row.original.guaranteedPrice ? <span>{ page.yes }</span> : <span>{ page.no }</span> }</div>),
                maxWidth: 130,
                minWidth: 130,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row._original.guaranteedPrice;
                  } else if (filter.value === "false"){
                    return !row._original.guaranteedPrice;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    <option value="true">{ page.yes }</option>
                    <option value="false">{ page.no }</option>
                  </select>
              },
              {
                Header: page.report.paymentType,
                accessor: "paymentType",
                Cell: row => ( <span>{ row.value && (row.value === "CARD" || row.value === "card") ? page.report.card : page.report.invoice }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.paymentTime,
                accessor: "paymentTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 125
              },
              {
                Header: page.report.passengerNotes,
                accessor: "passengerNotes",
                minWidth: 110,
                Cell: row => row.value ? <div className="options">{ row.value }<br/><span onClick={ () => { this.editComment(row.original.id, row.original.archival, row.original.projectId, row.value) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.editNotes }</span></div> : <div className="options">
                  <span onClick={ () => { this.editComment(row.original.id, row.original.archival, row.original.projectId) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.addNotes }</span>
                </div>
              }
            ] : [
              {
                Header: page.report.passengerName,
                accessor: "pname",
                minWidth: 95,
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { this.setState({ name: row.value }); this.listItems(this.state.startMonth, this.state.endMonth, row.value, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.voucher,
                accessor: "voucherName",
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.vouchers, { name: row.value })[0]; if(item) { this.setState({ selectedVoucher: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, item ? item.id : null, this.state.selectedDepartment.id, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.projectId,
                accessor: "projectName",
                minWidth: 100,
                Cell: row => <> <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.projects, { name: row.value })[0]; if(item) { this.setState({ selectedProject: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, item ? item.id : null) } }>{ row.value }</span>
                <div className="options">
                  <span onClick={ () => { this.editProject(row.original.id, row.original.archival, row.original.passengerNotes, row.original.projectId) } }><img src="/assets/img/edit-icon.png" alt=""/>{ row.value ? this.props.data.ordered.change : page.report.addProject }</span>
                </div>
                </>
              },
              {
                Header: page.report.mpk,
                accessor: "departmentName",
                minWidth: 100,
                Cell: row => <span style={{ cursor: "pointer" }} onClick={ () => { let item = _.filter(this.state.departments, { name: row.value })[0]; if(item) { this.setState({ selectedDepartment: item }) }; this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, item ? item.id : null, this.state.selectedProject.id) } }>{ row.value }</span>
              },
              {
                Header: page.report.invoiceDate,
                accessor: "invoiceBusinessDate",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.orderDate,
                accessor: "makeOrderTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.endDate,
                accessor: "finishTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.startAddress,
                Cell: row => ( <span>{ row.original.street + " " + row.original.buildingNr + ", " + row.original.city }</span> ),
                minWidth: 110
              },
              {
                Header: page.report.endAddress,
                accessor: "destAddress",
                minWidth: 110
              },
              {
                Header: page.report.route,
                minWidth: 110,
                Cell: row => <div className="options">
                  <span onClick={ () => { this.showMap(row) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.showMap }</span>
                </div>
              },
              {
                Header: page.report.co2Emission,
                accessor: "co2Emission",
                show: this.props.user.userType !== "USER" ? true : false,
                minWidth: 85
              },
              {
                Header: page.report.kilometers,
                accessor: "kmTraveled",
                show: this.props.user.userType === "SUPER" ? true : false,
                minWidth: 55
              },
              {
                Header: page.report.fees,
                accessor: "charge",
                minWidth: 65
              },
              {
                Header: page.report.netValue,
                accessor: "amountNetto",
                minWidth: 95
              },
              {
                Header: page.report.guaranteedPrice,
                accessor: "guaranteedPrice",
                headerClassName: "no-search",
                Cell: row => (<div>{ row.original.guaranteedPrice ? <span>{ page.yes }</span> : <span>{ page.no }</span> }</div>),
                maxWidth: 130,
                minWidth: 130,
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row._original.guaranteedPrice;
                  } else if (filter.value === "false"){
                    return !row._original.guaranteedPrice;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.profile.all }</option>
                    <option value="true">{ page.yes }</option>
                    <option value="false">{ page.no }</option>
                  </select>
              },
              {
                Header: page.report.paymentType,
                accessor: "paymentType",
                Cell: row => ( <span>{ row.value && (row.value === "CARD" || row.value === "card") ? page.report.card : page.report.invoice }</span> ),
                minWidth: 95
              },
              {
                Header: page.report.paymentTime,
                accessor: "paymentTime",
                Cell: row => ( <span>{ row.value ? this.parseDate(row.value, true) : "" }</span> ),
                minWidth: 125
              },
              {
                Header: page.report.passengerNotes,
                accessor: "passengerNotes",
                minWidth: 110,
                Cell: row => row.value ? <div className="options">{ row.value }<br/><span onClick={ () => { this.editComment(row.original.id, row.original.archival, row.original.projectId, row.value) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.editNotes }</span></div> : <div className="options">
                  <span onClick={ () => { this.editComment(row.original.id, row.original.archival, row.original.projectId) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.report.addNotes }</span>
                </div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "", comment: null, passengerNotes: null, projId: null }) }, 500) } }>{ Popup }</Modal>
          <Modal open={ this.state.generatePopup } hidePopup={ () => { this.setState({ generatePopup: false }) } }>
            <div className="password-modal generate">
              <div className="inputs">
                <h3>{ page.report.filters }</h3>
                <div className="input">
                  <label>{ page.report.nameLastname }</label>
                  <input type="text" name="name" id="name" value={ this.state.name } onChange={ this.handleInputChange }/>
                </div>
                <div className="input">
                  <label>{ page.voucher.range }</label>
                  <div className="flex">
                    <div>
                      <label>{ page.voucher.from }</label>
                      <DatePicker
                          selected={this.state.startMonth}
                          onChange={(date) => {
                            this.setState({ startMonth: date })
                          }}
                          locale="pl"
                          dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div>
                      <label>{ page.voucher.to }</label>
                      <DatePicker
                          selected={this.state.endMonth}
                          onChange={(date) => {
                            this.setState({ endMonth: date })
                          }}
                          locale="pl"
                          dateFormat="dd/MM/yyyy"
                        />
                    </div>
                  </div>
                </div>
                <div className="input">
                  <label>{ page.report.voucher }</label>
                  <Select noResults={ this.props.data.table.noResults } selected={ this.state.selectedVoucher.id } data={this.state.vouchers} filters={['code']} placeholder={ page.type } changeValue={ (data) => { this.setState({ selectedVoucher: data }) } }/>
                </div> 
                <div className="input">
                  <label>{ page.report.projectId }</label>
                  <Select noResults={ this.props.data.table.noResults } selected={ this.state.selectedProject.id } data={this.state.projects} filters={['name']} placeholder={ page.type } changeValue={ (data) => { this.setState({ selectedProject: data }) } }/>
                </div> 
                <div className="input">
                  <label>{ page.report.mpk }</label>
                  <Select noResults={ this.props.data.table.noResults } selected={ this.state.selectedDepartment.id } data={this.state.departments} filters={['name']} placeholder={ page.type } changeValue={ (data) => { this.setState({ selectedDepartment: data }) } }/>
                </div> 
              </div>
              <span className="more" onClick={ this.clearFilters }>{ page.report.clearFilters }</span>
              <div className="buttons">
                <button className="btn yellow small" id="button" onClick={ () => { if(!this.state.printPreloader) { this.getReport(); this.setState({ generatePopup: false }) } } }><span>{ page.report.downloadReportWithFilters }</span></button><br/><br/>
                <button className="btn violet small" id="button2" onClick={ () => { if (!this.state.printPreloader) { this.listItems(this.state.startMonth, this.state.endMonth, this.state.name, this.state.selectedVoucher.id, this.state.selectedDepartment.id, this.state.selectedProject.id); this.setState({ generatePopup: false }) } } }><span>{ page.report.filterInTable }</span></button>
              </div>
              <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.printPreloader ? <span className="preloader-mini"></span> : null }</div>
            </div>
          </Modal>
          <Modal open={ this.state.mapPopup } hidePopup={ () => { this.setState({ mapPopup: false, mapPositions: [] }) } }>{ mapModal }</Modal>
        </div>
    )
  }
}
