import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Table from './Table'
import Modal from './Modal'
import Select from './Select'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      type: this.props.match.params.type ? this.props.match.params.type : "list", 
      action: "",
      project: { active: true }, projectS: [], itemPopup: false, id: null, rewrite: {},
      preloader: true,
      projectMinLenghtError: false,
      projectValidationError: false,
    }
    this._bind(
      'listItems', 
      'addItem', 
      'editItem', 
      'successEdit', 
      'deleteItem', 
      'successListItems', 
      'saveItem', 
      'processDeleteItem', 
      'changeValue', 
      'rewriteItem', 
      'successRewriteItem', 
      'shortFetch',
      'handleInputChange',
      'deleteSuccess',
      'update',
      'validate'
    )

    this.endpoint = "projects"

    this.listItems()

    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  update() {
    this.setState({ project: { active: true }, itemPopup: false, id: null, rewrite: {} })
    this.listItems()
  }

  validate(fields) {
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.match.params.page) {
      this.props.history.replace(this.props.lang === "pl" ? "/manage/mpk" : "/en/manage/mpk")
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
    .then((response) => {
      if (response.status === 401) {
        this.props.checkSession()
      } else if (response.status === 404 || response.status >= 500) {
        this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
      } else {
        return response.json()
      }
    })
    .then((result) => {
      if (result.answer === "OK") {
        success(result)
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError}}), true)
      }
      if (result.fieldData.fields && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.loginPage.genericError }}), true)
    })
  }

  handleInputChange(type, event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state[type]

    item[name] = value

    if( event.target.className === "input-error" ) event.target.className = ""
    if (type === 'project') {
      this.setState(this.setState({projectMinLenghtError: false}))
      this.setState({projectValidationError: false})
      const pattern = new RegExp(/^[a-zA-Z0-9_@#&*$.\-, ]+$/)

      if (event.target.value.length < 3 ) this.setState({projectMinLenghtError: true});
      if (!pattern.test(event.target.value)) this.setState({projectValidationError: true});
    }
  

    this.setState({
      [type]: item
    })
  }

  listItems() {
    this.setState({ preloader: true })
    this.shortFetch("POST", this.endpoint+"", {}, this.successListItems)
  }

  successListItems(data) {    
    this.setState({ data: data.result.projects, itemPopup: false })
    setTimeout(() => { this.setState({ preloader: false }) }, 100)
  }

  addItem() {
    this.setState({ project: { active: true }, itemPopup: true, action: "add" })
  }

  editItem(id) {
    this.shortFetch("GET", this.endpoint+"/"+id, null, this.successEdit)
  }

  successEdit(data) {
    this.setState({ project: data.result.project, itemPopup: true, action: "edit" })
  }

  saveItem() {
    if (!this.state.projectMinLenghtError && !this.state.projectValidationError) {
      window.gtag('event', "zarzadzanie_dodaj_projekt")
      if (this.state.project.id) {
        this.shortFetch("POST", this.endpoint+"/edit", { project: this.state.project }, () => { 
          this.setState({ itemPopup: false, action: "" })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.saveSuccessfully }}), true)
        })
      } else {
        this.shortFetch("POST", this.endpoint+"/add", { project: this.state.project }, () => { 
          this.setState({ itemPopup: false, action: "" })
          this.listItems()
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.addSuccessfully }}), true)
        })
      }
    }
  }

  deleteItem(id, count) {
    this.setState({ itemPopup: true, action: count > 0 ? "delete_rewrite" : "delete", id: id })
  }

  processDeleteItem() {
    if (this.state.action === "delete_rewrite") {
      this.rewriteItem()
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    } else {
      this.shortFetch("POST", this.endpoint+"/delete/"+this.state.id, null, this.deleteSuccess)
    }
  }

  deleteSuccess() {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.deleteSuccessfully }}), true)
    this.listItems()
  }

  rewriteItem() {
    this.shortFetch("POST", this.endpoint+"/rewrite", { from: this.state.id, to: this.state.rewrite.id }, this.successRewriteItem)
  }

  successRewriteItem(data) {
    window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : this.props.data.manage.rewriteSuccessfully }}), true)
  }

  changeValue(item) {
    this.setState({ rewrite: item })
  }

  render () {
    const page = this.props.data.manage

    let Popup = null

    if (this.state.action === "delete_rewrite" || this.state.action === "delete") {
      Popup = (
        <div className="password-modal">
          <div className="inputs delete">
            <h3>{ page.deleteTitle }</h3>
            <p><strong>{ this.state.action === "delete_rewrite" ? page.project.confirmRewrite : page.project.confirm }</strong></p>
            { this.state.action === "delete_rewrite" ? <div className="input">
              <label>{ page.project.rewrite }</label>
              <Select noResults={ this.props.data.table.noResults } data={this.state.data} filters={['name']} placeholder={"Wpisz..."} id={ this.state.id } changeValue={ this.changeValue }/>
            </div> : null }
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.processDeleteItem }><span>{ page.delete }</span></button>
          </div>
        </div>
      )
    } else {
      Popup = (
        <div className="password-modal">
          <div className="inputs">
            <h3>{ this.state.action === "edit" ? page.edit : page.add }</h3>
            <div className="input">
              <label htmlFor="name">{ page.project.name }</label>
              <input type="text" name="name" id="name" value={ this.state.project.name } className={ this.state.passError ? "input-error" : "" } onChange={ this.handleInputChange.bind(null, "project") } required/>
              { this.state.projectValidationError ? <p className="error">{ page.project.validationError }</p> : null }
              { this.state.projectMinLenghtError ? <p className="error">{ page.project.minLengthError }</p> : null }  
            </div>
            <div className="input">
              <label htmlFor="description">{ page.project.description }</label>
              <textarea type="number" name="description" id="description" value={ this.state.project.description } onChange={ this.handleInputChange.bind(null, "project") } required></textarea>
            </div>
            <div className="input">
              <div className="check-box"><input name="active" id="cb2" type="checkbox" checked={ this.state.project.active !== undefined ? this.state.project.active : true } onChange={ this.handleInputChange.bind(null, "project") }/><label htmlFor="cb2">Czy aktywny?</label></div>
            </div>
          </div>
          <div className="buttons">
            <button className="btn yellow small" id="button" onClick={ this.saveItem }><span>{ page.save }</span></button>
          </div>
        </div>
      )
    }

    return (
        <div className="box">
          <div className="tabs">
            <NavLink to={ this.props.lang === "pl" ? '/manage/mpk' : '/en/manage/mpk' }>{ page.mpkName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/profiles' : '/en/manage/profiles' }>{ page.profilesName }</NavLink>
            <span className="sep">|</span>
            <NavLink to={ this.props.lang === "pl" ? '/manage/projects' : '/en/manage/projects' }>{ page.projectsName }</NavLink>
          </div>
          <div className="table-outer">
            <div className="top">
              <h3>{ page.project.title } { this.props.user.partner && this.props.user.partner.name ? this.props.user.partner.name : this.props.user.partnerName }</h3>
              <div className="buttons">
                <button className="btn small violet" onClick={ this.addItem }><span>{ page.add }</span></button>
              </div>
            </div>
            <div className="refresh">{ this.state.preloader ? <span className="preloader-mini"></span> : <img src="/assets/img/refresh.png" alt="Refresh" onClick={ this.update }/> }</div>
            <Table lang={ this.props.lang } loading={ this.state.preloader } columns={[
              {
                Header: page.project.name,
                accessor: "name"
              },
              {
                Header: page.project.description,
                accessor: "description"
              },
              {
                Header: page.project.isActive,
                accessor: "active",
                headerClassName: "no-search",
                Cell: row => (<div style={{ textAlign: "center" }} className="check-box"><input id="cb" type="checkbox" disabled checked={ row.value }/><label htmlFor="cb"></label></div>),
                maxWidth: "60px",
                filterMethod: (filter, row) => {
                  if (filter.value === "all") {
                    return true;
                  }
                  if (filter.value === "true") {
                    return row[filter.id] === true;
                  } else {
                    return row[filter.id] === false;
                  }
                },
                Filter: ({ filter, onChange }) =>
                  <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                  >
                    <option value="all">{ page.project.all }</option>
                    <option value="true">{ page.project.active }</option>
                    <option value="false">{ page.project.inactive }</option>
                  </select>
              },
              {
                Header: page.options,
                maxWidth: 180,
                minWidth: 180,
                filterable: false,
                sortable: false,
                headerClassName: "no-search",
                Cell: row => <div className="options"><span onClick={ () => { this.editItem(row.original.id) } }><img src="/assets/img/edit-icon.png" alt=""/>{ page.edit }</span><span onClick={ () => { this.deleteItem(row.original.id) } }><img src="/assets/img/delete-icon.png" alt=""/>{ page.delete }</span></div>
              }
            ]}
            data={ this.state.data }/>
          </div>
          <Modal open={ this.state.itemPopup } hidePopup={ () => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) } }>{ Popup }</Modal>
        </div>
    )
  }
}
