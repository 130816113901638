import React from 'react'
import BaseComponent from './BaseComponent'
import SearchInput, {createFilter} from 'react-search-input'
import _ from 'lodash'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { searchTerm: "", dropdown: false, item: {} }
    this._bind('windowClick', 'searchUpdated', 'select', 'update')
  
    window.addEventListener("UPDATE", (data) => { this.update() })
    window.addEventListener("CLEAR_PROJECT_INPUT", (data) => { this.update() })
  }

  update() {
    this.setState({ searchTerm: "", dropdown: false, item: {} })
  }

  componentDidMount() {
    this.mounted = true
    window.addEventListener('click', this.windowClick)

    if (this.props.selected) {
      //console.log("select: ", nextProps)
      let selected = _.filter(this.props.data, { id: this.props.selected.toString() })[0] || {}
      this.setState({ searchTerm: selected.name || selected.code })
    }

    if (this.props.selectedAddress) {
      this.setState({ searchTerm: this.props.selectedAddress.name })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    window.removeEventListener('click', this.windowClick)
  }

  windowClick() {
    setTimeout(() => {
      if (this.mounted) {
        this.setState({ dropdown: false })
      }
    }, 100)
  }

  searchUpdated(term) {
    this.setState({searchTerm: term})
    if(this.props.onChange) {
      this.props.onChange(term)
    }
    if(document.getElementById(this.props.id) && document.getElementById(this.props.id).className === "input-error") document.getElementById(this.props.id).className = ""
  }

  select(item) {
    if (item) {
      this.setState({ searchTerm: item.name || item.code, item: item })
      this.props.changeValue(item)
    }
    if(document.getElementById(this.props.id) && document.getElementById(this.props.id).className === "input-error") document.getElementById(this.props.id).className = ""
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log("select: ", nextProps)
    if (nextProps.selected && nextProps.selected !== this.props.selected) {
      let list = [...nextProps.data]
      this.select(_.filter(list, { id: nextProps.selected.toString() })[0])
    }

    if (nextProps.selectedAddress && nextProps.selectedAddress !== this.props.selectedAddress) {
      this.setState({ searchTerm: nextProps.selectedAddress.name })
    }
  }

  render () {
    let filteredData = _.filter(this.props.data, (item) => {
      return item.name || item.code
    })

    let list = _.orderBy(filteredData, [user=>user.name ? user.name.toLowerCase() : user.code.toLowerCase(), 'name'], 'asc')

    if (this.props.onChange === undefined ) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].id === this.props.id || list[i].deleted === true) {
          let index = list.indexOf(list[i])
          list.splice(index, 1)
        }
      }
    }

    let filteredList = []

    if (this.props.onChange !== undefined ) {
      filteredList = list
    } else if(list.length > 0) {
      filteredList = list.filter(createFilter(this.state.searchTerm || "", this.props.filters))
    }

    let items = filteredList.map((item, i) => {
      return (
        <li key={ i } onClick={ this.select.bind(null, item) }>
          { item.name || item.code }
        </li>
      )
    })

    return (
      <div className="select-search">
        <SearchInput disabled={ this.props.disabled || this.props.isProjectInputDisabled} type="search" autoComplete="off" placeholder={ this.props.placeholder } value={ this.state.searchTerm } onChange={ this.searchUpdated } onClick={ (e) => { e.stopPropagation(); this.setState({ dropdown: true }) } } id={ this.props.id }/>
        { this.state.searchTerm !== "" && !JSON.parse(localStorage.getItem("projectRequired")) ? <div className="reset" onClick={ (e) => { e.stopPropagation(); this.setState({ searchTerm: "", id: null }); this.props.changeValue({name: ""}) } }><img src="/assets/img/delete-icon.png" alt=""/></div> : null }
        <ul className={ this.state.dropdown ? "open" : "" }>
          { items.length > 0 ? items : <p>{ this.props.noResults }</p> }
        </ul>
      </div>
    )
  }
}
