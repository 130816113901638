import React from 'react'
import BaseComponent from './BaseComponent'
import _ from 'lodash'
import DatePicker from "react-datepicker"
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet'
import Leaflet from 'leaflet'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import Modal from './Modal'
import Select from './Select'
registerLocale('pl', pl)

class UserComponent extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: "", focus: false, users: [], usersArray: [], voucherCode: "", wrongVoucher: false,
      user: {
        fellowPassenger: 0,
        phone: "",
        passengerName: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.firstname + " " + this.props.user.lastname : "",
        cash: false,
        voucherId: null,
        businessUserId: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.id : null
      },
      selectedVoucher: {},
      voucher: {},
      orderDays: {},
      orderDaysMapArray: [],
      carClassMapArray: [],
      send: true,
      isVoucherInputDisabled: false,
    }
    this._bind('setIsVoucherInputDisabled','searchUpdated', 'getUsers', 'successGetUsers', 'changeUser', 'handleInputChange', 'update', 'addVoucher', 'saveVoucher', 'validate')
    this.KEYS_TO_FILTERS = ['name']

    if (this.props.user.userType !== "USER") {
      this.getUsers()
    }

    this.handleVoucherChange = _.debounce(e => {
      if (e) {
        this.shortFetch("GET", "vouchers/search/" + e, null, (data) => {
          if (data.result.vouchers.length >= 1) {
            let validVoucher = false
            for (var i = 0; i < data.result.vouchers.length; i++) {
              if (data.result.vouchers[i].code.toLowerCase() === e.toLowerCase()) {
                let user = this.state.user
                validVoucher = true
                if (data.result.vouchers[i].businessUserId || data.result.vouchers[i].personName) {
                  user.passengerName = data.result.vouchers[i].businessUserId ? this.state.users[data.result.vouchers[i].businessUserId] : data.result.vouchers[i].personName
                  user.businessUserId = data.result.vouchers[i].businessUserId
                }
                user.voucherId = data.result.vouchers[i].id
                if (data.result.vouchers[i].projectId && data.result.vouchers[i].projectName) {
                  this.props.setProjectFromVoucher({
                    id: data.result.vouchers[i].projectId,
                    name: data.result.vouchers[i].projectName
                  })
                  this.props.setIsProjectInputDisabled(true);
                }
               
                this.setState({ selectedVoucher: data.result.vouchers[i], user: user, wrongVoucher: false })
              }
            }
            if (!validVoucher) {
              let user = this.state.user
              user.voucherId = ""
              this.setState({ selectedVoucher: {}, user: user, wrongVoucher: true })
              this.props.setIsProjectInputDisabled(false);
              window.dispatchEvent(new CustomEvent("CLEAR_PROJECT_INPUT", { 'detail': {} }), true)
            }
          } else {
            let user = this.state.user
            user.voucherId = ""
            this.setState({ selectedVoucher: {}, user: user, wrongVoucher: true })
            this.props.setIsProjectInputDisabled(false);
            window.dispatchEvent(new CustomEvent("CLEAR_PROJECT_INPUT", { 'detail': {} }), true)
          }
        })
      }
    }, 600);

    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  setIsVoucherInputDisabled(isDisabled) {
    if (isDisabled) {
      this.props.setIsProjectInputDisabled(false);
      let user = this.state.user
            user.voucherId = ""
      this.setState({
        isVoucherInputDisabled: isDisabled,
        voucher: "",
        voucherCode: "",
        selectedVoucher: {},
        wrongVoucher: false,
      });
      window.dispatchEvent(new CustomEvent("CLEAR_PROJECT_INPUT", { 'detail': {} }), true);
    } else {
      this.setState({isVoucherInputDisabled: isDisabled});
    }
  }

  addVoucher() {
    this.shortFetch("GET", "vouchers/new", null, (data) => {
      let voucherTypes = []
      for (var i = 0; i < Object.entries(data.result.voucherTypes).length; i++) {
        let item = { id: Object.entries(data.result.voucherTypes)[i][0], name: Object.entries(data.result.voucherTypes)[i][1] }
        voucherTypes.push(item)
      }
      let carClass = []
      for (var j = 0; j < Object.entries(data.result.carClassMap).length; j++) {
        let item = { id: Object.entries(data.result.carClassMap)[j][0], name: Object.entries(data.result.carClassMap)[j][1] }
        carClass.push(item)
      }
      let orderDays = []
      for (var k = 0; k < Object.entries(data.result.orderDays).length; k++) {
        let item = { id: Object.entries(data.result.orderDays)[k][0], name: Object.entries(data.result.orderDays)[k][1] }
        orderDays.push(item)
      }
      let departments = []
      for (var l = 0; l < Object.entries(data.result.departments).length; l++) {
        let item = { id: Object.entries(data.result.departments)[l][0], name: Object.entries(data.result.departments)[l][1] }
        departments.push(item)
      }
      let projects = []
      for (var m = 0; m < Object.entries(data.result.projects).length; m++) {
        let item = { id: Object.entries(data.result.projects)[m][0], name: Object.entries(data.result.projects)[m][1] }
        projects.push(item)
      }
      let voucher = data.result.voucher
      voucher.activationDate = new Date()
      voucher.active = true
      delete voucher.mobile
      this.setState({ voucher: voucher, voucherTypes: voucherTypes, carClass: data.result.carClassMap, carClassMapArray: carClass, orderDays: data.result.orderDays, orderDaysMapArray: orderDays, departments: data.result.departments, departmentsArray: departments, projects: data.result.projects, projectsArray: projects, canGenerateMulti: data.result.canGenerateMulti, maxCounter: data.result.maxCounter, itemPopup: true, action: "add" })
    })
  }

  update() {
    this.setState({
        searchTerm: "", focus: false, users: [], usersArray: [], voucher: "", voucherCode: "", wrongVoucher: false, user: {
        fellowPassenger: 0,
        phone: "",
        passengerName: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.firstname + " " + this.props.user.lastname : "",
        cash: false,
        voucherId: null,
        voucherCode: "",
        businessUserId: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.id : null
      }
    });

    this.props.setIsProjectInputDisabled(false);

    if (this.props.user.userType !== "USER") {
      this.getUsers();
    }
  }

  validate(fields) {
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if (document.getElementById(field)) document.getElementById(field).className = "input-error"
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if (first[0]) first[0].focus()
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          success(result)
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[i].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        this.setState({isLoading: false})
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      })
  }

  getUsers() {
    this.shortFetch("GET", "users/short", null, this.successGetUsers)
  }

  successGetUsers(data) {
    let users = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      users.push(item)
    }
    this.setState({ users: data.result.data, usersArray: users })
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term })
  }

  changeUser(u) {
    let user = this.state.user
    user.businessUserId = u.id
    user.passengerName = u.name
    this.setState({ user: user })
    this.props.updateUser(this.props.i, user)
  }

  handleInputChange(type, event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    let item = this.state[type]

    item[name] = value

    this.setState({
      [type]: item
    })

    if(type === "user") this.props.updateUser(this.props.i, item)
  }

  saveVoucher() {
    window.gtag('event', "voucher_generowanie")
    let voucher = this.state.voucher
    this.shortFetch("POST", "vouchers/addSingle", { voucher: voucher, send: this.state.send }, (data) => {
      let user = this.state.user
      user.voucherId = data.result.id
      this.setState({ itemPopup: false, user: user, voucherCode: data.result.code })
      this.handleVoucherChange(data.result.code)
      this.props.updateUser(this.props.i, user)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedUser !== this.state.user) {
      this.setState({ user: nextProps.selectedUser, })
    }
  }

  render() {
    const page = this.props.data.manage
    const orderPage = this.props.data.order

    let startDate = new Date()
    startDate.setHours(this.state.voucher.startHour !== undefined ? this.state.voucher.startHour : 0)
    startDate.setMinutes(this.state.voucher.startMinute !== undefined ? this.state.voucher.startMinute : 0)

    let endDate = new Date()
    endDate.setHours(this.state.voucher.endHour !== undefined ? this.state.voucher.endHour : 0)
    endDate.setMinutes(this.state.voucher.endMinute !== undefined ? this.state.voucher.endMinute : 0)

    let voucherPopup = (
      <div className="password-modal voucher">
        <div className="inputs">
          <h3>{page.generateVoucherBtn}</h3>
        </div>
        <div className="inputs">
          <div className="input">
            <label htmlFor="personName">{page.voucher.personName}</label>
            <input type="text" name="personName" id="personName" value={this.state.voucher.personName || ""} onChange={this.handleInputChange.bind(null, "voucher")} required />
          </div>
          <div className="input">
            <label>{page.voucher.departmentId}</label>
            <Select noResults={ this.props.data.table.noResults } selected={this.state.voucher.departmentId} data={this.state.departmentsArray} filters={['name']} placeholder={page.type} changeValue={(item) => {
              let voucher = this.state.voucher
              voucher.departmentId = item.id
              this.setState({ department: item, voucher: voucher })
            }} id="departmentId" />
          </div>
          <div className="input">
            <label>{page.voucher.projectId}</label>
            <Select noResults={ this.props.data.table.noResults } selected={this.state.voucher.projectId} data={this.state.projectsArray} filters={['name']} placeholder={page.type} changeValue={(item) => {
              let voucher = this.state.voucher
              voucher.projectId = item.id
              this.setState({ project: item, voucher: voucher })
            }} />
          </div>
          <div className="input">
            <label>{page.voucher.activationDate}</label>
            <DatePicker
              selected={this.state.voucher.activationDate}
              onChange={(date) => {
                let voucher = this.state.voucher
                voucher.activationDate = date
                this.setState({ voucher: voucher })
              }}
              locale="pl"
              dateFormat="dd/MM/yyyy, HH:mm"
              id="activationDate"
              type="search"
              showTimeSelect
              timeFormat="p"
              timeIntervals={30}
              timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
            />
          </div>
          <div className="input">
            <label>{page.voucher.expirationDate}</label>
            <DatePicker
              selected={this.state.voucher.expirationDate}
              onChange={(date) => {
                let voucher = this.state.voucher
                voucher.expirationDate = date
                this.setState({ voucher: voucher })
              }}
              locale="pl"
              dateFormat="dd/MM/yyyy, HH:mm"
              showTimeSelect
              timeFormat="p"
              timeIntervals={30}
              timeCaption={ this.props.lang === "pl" ? "Godzina" : "Hour" }
            />
          </div>
          <div className="input">
            <label htmlFor="orderDays">{page.voucher.orderDays}</label>
            <select name="orderDays" id="orderDays" value={this.state.voucher.orderDays} onChange={this.handleInputChange.bind(null, "voucher")}>
              <option value="">{page.select}</option>
              {
                this.state.orderDaysMapArray.map((item, i) => {
                  return <option key={i} value={item.id}>{item.name}</option>
                })
              }
            </select>
          </div>
          <div className="input time">
            <label>{page.voucher.hourLimit}</label>
            <div className="flex">
              <div>
                <label>{page.voucher.from}</label>
                <DatePicker
                  selected={startDate}
                  onChangeRaw={(e) => {
                    let pattern = new RegExp("^[0-9:]*$");
                    if (!pattern.test(e.target.value)) { e.preventDefault() }
                  }
                  }
                  onChange={(date) => {
                    if (date) {
                      let hours = date.getHours()
                      let minutes = date.getMinutes()
                      let voucher = this.state.voucher
                      voucher.startHour = hours
                      voucher.startMinute = minutes
                      this.setState({ voucher: voucher })
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="pl"
                  dateFormat="HH:mm"
                  timeFormat="p"
                  timeIntervals={30}
                  timeCaption="Godzina"
                />
              </div>
              <div>
                <label>{page.voucher.to}</label>
                <DatePicker
                  selected={endDate}
                  onChangeRaw={(e) => {
                    let pattern = new RegExp("^[0-9:]*$");
                    if (!pattern.test(e.target.value)) { e.preventDefault() }
                  }
                  }
                  onChange={(date) => {
                    if (date) {
                      let hours = date.getHours()
                      let minutes = date.getMinutes()
                      let voucher = this.state.voucher
                      voucher.endHour = hours
                      voucher.endMinute = minutes
                      this.setState({ voucher: voucher })
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="pl"
                  dateFormat="HH:mm"
                  timeFormat="p"
                  timeIntervals={30}
                  timeCaption="Godzina"
                />
              </div>
            </div>
          </div>
          <div className="input">
            <label htmlFor="amountZl">{page.voucher.amountZl}</label>
            <input type="number" min="0" name="amountZl" id="amountZl" value={this.state.voucher.amountZl} onChange={this.handleInputChange.bind(null, "voucher")} required />
          </div>
          <div className="input">
            <label htmlFor="maxTariffGr">{page.voucher.maxTariffGr}</label>
            <input type="number" min="0" name="maxTariffGr" id="maxTariffGr" value={this.state.voucher.maxTariffGr} onChange={this.handleInputChange.bind(null, "voucher")} required />
          </div>
          <div className="input">
            <label htmlFor="carClass">{page.voucher.carClass}</label>
            <select name="carClass" id="carClass" value={this.state.voucher.carClass} onChange={this.handleInputChange.bind(null, "voucher")}>
              <option value="">{page.select}</option>
              {this.state.carClassMapArray.map((item, key) => {
                return <option key={key} value={item.id}>{item.name}</option>
              })}
            </select>
          </div>
          <div className="input">
            <label htmlFor="email">{page.voucher.email}</label>
            <input type="text" name="email" id="email" value={this.state.voucher.email} onChange={this.handleInputChange.bind(null, "voucher")} required />
          </div>
          <div className="input">
            <div className="check-box"><input name="active" id="cb1" type="checkbox" checked={this.state.voucher.active} onChange={this.handleInputChange.bind(null, "voucher")} /><label htmlFor="cb1">{page.voucher.active}</label></div>
          </div>
          <div className="input comment">
            <label htmlFor="orderComment">{page.voucher.orderComment}</label>
            <textarea name="orderComment" id="orderComment" value={this.state.voucher.orderComment} onChange={this.handleInputChange.bind(null, "voucher")} required></textarea>
          </div>
        </div>
        <div className="buttons vouchers">
          <button className="btn yellow small" id="button" onClick={() => { let voucher = this.state.voucher; voucher.email = ""; this.setState({ voucher: voucher, send: false }); this.saveVoucher() }}><span>{this.state.action === "edit" ? page.save : page.voucher.btnGenerate}</span></button>
          {this.state.voucher.email && this.state.voucher.email !== "" ? <button className="btn violet small" id="button" onClick={this.saveVoucher}><span>{page.voucher.btnGenerateAndSend}</span></button> : null}
        </div>
      </div>
    )

    return (
      <div className="single">
        {this.props.match.params.type === "guest" || (this.state.user.businessUserId === null && this.props.match.params.type === "edit") ? <div className={ this.props.lang === "pl" ? "input guest-input" : "input guest-input en" }>
          <label>{ orderPage.orderFor }{this.props.i === 0 ? "*" : ""}{this.props.i !== 0 ? <span className="delete" onClick={this.props.deleteUser.bind(null, this.props.i)}>{ orderPage.delete }</span> : null}</label>
          <input type="text" autoComplete="off" placeholder="" name="passengerName" id="passengerName" value={this.state.user.passengerName} onChange={this.handleInputChange.bind(null, "user")} disabled={ this.state.selectedVoucher.personName && !this.state.user.cash && this.state.voucherCode }/>
          <label>{ orderPage.phone }</label>
          <input className="phone" autoComplete="off" type="text" placeholder="" name="phone" value={this.state.user.phone} onChange={this.handleInputChange.bind(null, "user")} style={{ paddingLeft: "20px" }} id="phone" />
          <div className="guest">
            <label>{ orderPage.payment }</label>
            <div className="select">
              <input type="radio" onChange={() => {}} checked={!this.state.user.cash} name={"payment-voucher" + this.props.i} id={"payBy-voucher" + this.props.i} onClick={(e) => { let user = this.state.user; user.cash = false; this.setState({ user: user }); this.props.updateUser(this.props.i, user); this.props.setIsGenerateVoucherButtonDisabled(false); this.setIsVoucherInputDisabled(false)}} />
              <label htmlFor={"payBy-voucher" + this.props.i}>{ orderPage.voucher }</label>
            </div>
            <div className="select">
              <input disabled={ this.props.user.extraFilter } type="radio" onChange={() => {}} checked={this.state.user.cash} name={"payment-cash" + this.props.i} id={"payBy-cash" + this.props.i} onClick={(e) => { let user = this.state.user; user.cash = true; this.setState({ user: user }); this.props.updateUser(this.props.i, user); this.props.setIsGenerateVoucherButtonDisabled(true); this.setIsVoucherInputDisabled(true)}} />
              <label className={ this.props.user.extraFilter ? "disabled" : "" } htmlFor={"payBy-cash" + this.props.i}>{ orderPage.card }</label>
            </div>
            <label className={this.state.isVoucherInputDisabled ? 'disabled' : null}>{ orderPage.voucherCode }</label>
            <input type="text" autoComplete="off" disabled={this.state.isVoucherInputDisabled} placeholder={this.props.data.manage.type} name="voucherCode" value={this.state.voucherCode} onChange={(e) => { this.setState({ voucherCode: e.target.value }); this.handleVoucherChange(e.target.value) }} style={{ paddingLeft: "20px" }} />
            {this.state.wrongVoucher ? <p className="voucher-error">{ orderPage.wrongVoucher }</p> : null}
            <button className="btn small green" disabled={this.props.isGenerateVoucherButtonDisabled} onClick={this.addVoucher}><span>{ orderPage.generateVoucher }</span></button>
          </div>
        </div> : <div className="input">
            <label>{ orderPage.orderFor }{this.props.i === 0 ? "*" : ""}{this.props.i !== 0 ? <span className="delete" onClick={this.props.deleteUser.bind(null, this.props.i)}>{ this.props.data.manage.delete }</span> : null}</label>
            {this.props.user.userType === "USER" ? <input autoComplete="off" type="text" placeholder="" name="passengerName" id="passengerName" value={this.props.user.firstname + " " + this.props.user.lastname} readOnly onChange={this.handleInputChange.bind(null, "user")} /> : <Select noResults={ this.props.data.table.noResults } selected={this.state.user.businessUserId} data={this.state.usersArray} filters={['name']} placeholder={this.props.data.manage.type} changeValue={this.changeUser} id="passengerName" />}
            <div className="">
              <label>{ orderPage.payment }</label>
              <div className="select">
                <input type="radio" defaultChecked name={"payment-" + this.props.i} id={"payBy-" + this.props.i} />
                <label htmlFor={"payBy-" + this.props.i}>{ orderPage.businessPayment }</label>
              </div>
            </div>
          </div>}
        { /*<div className="input">
          <label>{ orderPage.passengers }</label>
          <select value={this.state.user.fellowPassenger} name="fellowPassenger" onChange={this.handleInputChange.bind(null, "user")}>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div> */ }
        <Modal open={this.state.itemPopup} hidePopup={() => { this.setState({ itemPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) }}>{voucherPopup}</Modal>
      </div>
    )
  }
}

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    let nearestHalfHourVal = this.nearestHalfHour();
    this.state = {
      userList: [{
        fellowPassenger: 0,
        phone: "",
        passengerName: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.firstname + " " + this.props.user.lastname : "",
        cash: false,
        voucherId: null,
        businessUserId: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.id : null
      }],
      start: "",
      end: "",
      project: {},
      orderComment: "",
      startDate: nearestHalfHourVal,
      map: { lat: 52.237901, lng: 21.036469, zoom: 12 },
      startSuggestions: [],
      endSuggestions: [],
      projects: [],
      projectsArray: [],
      saveComment: false,
      date: "now",
      startSelectedAddress: { name: "" },
      endSelectedAddress: { name: "" },
      carClass: "ECONOMIC",
      estate: false,
      animals: false,
      silentRide: false,
      hybrid: false,
      langDe: false,
      filtersPopup: false,
      orderNr: [],
      oplaty: false,
      more: false,
      addAnother: false,
      area: {},
      selectedArea: -1,
      priceHash: null,
      guaranteedPrice: null,
      guaranteedPriceSelected: false,
      isGenerateVoucherButtonDisabled: false,
      isProjectInputDisabled: false,
      isLoading: false,
      isCGWLoading: false,
    }

    this._bind(
      'updateUser',
      'selectUser',
      'addUser',
      'handleInputChange',
      'deleteUser',
      'handleDateChange',
      'getLocation',
      'fetchSuggestionsStart',
      'fetchSuggestionsSuccessStart',
      'fetchSuggestionsEnd',
      'fetchSuggestionsSuccessEnd',
      'changeStartAddress',
      'getArea',
      'changeEndAddress',
      'getProjects',
      'successGetProjects',
      'changeProject',
      'makeOrder',
      'resetStartAddress',
      'update',
      'getOrder',
      'getGuaranteedPrice',
      'successGetGuaranteedPrice',
      'setIsGenerateVoucherButtonDisabled',
      'setIsProjectInputDisabled'
    )

    this.getProjects()

    if (this.props.match.params.type === "edit") {
      setTimeout(() => { 
        this.getOrder()
      }, 500)
    }

    this.getSuggestionsStart = _.debounce(e => {
      if (e.length >= 3) {
        this.fetchSuggestionsStart(e)
      }
    }, 600);

    this.getSuggestionsEnd = _.debounce(e => {
      if (e.length >= 3) {
        this.fetchSuggestionsEnd(e)
      }
    }, 600);

    this.getPrice = _.debounce(e => {
      if (this.state.startSelectedAddress.name !== '' && this.state.endSelectedAddress !== '') {
        this.getGuaranteedPrice()
        // console.log("debounced")
      }
    }, 1000);

    window.addEventListener("UPDATE", (data) => { this.update() })
  }

  setIsGenerateVoucherButtonDisabled(isDisabled) {
    this.setState({isGenerateVoucherButtonDisabled: isDisabled})
  }
  setIsProjectInputDisabled(isDisabled) {
    this.setState({isProjectInputDisabled: isDisabled})
  }

  getOrder() {
    this.shortFetch("GET", "order/" + this.props.match.params.id, null, (data) => {
      let user = this.state.userList[0]
      user.fellowPassenger = data.result.passenger[0].fellowPassenger
      user.businessUserId = data.result.passenger[0].businessUserId
      user.cash = data.result.passenger[0].cash
      user.passengerName = data.result.passenger[0].passengerName
      user.phone = data.result.passenger[0].phone
      user.voucherId = data.result.passenger[0].voucherId
      let start = {
        bNum: data.result.start.buildingNumber,
        city: data.result.start.city,
        lat: data.result.start.lat,
        lng: data.result.start.lon,
        street: data.result.start.street,
        name: data.result.start.street + " " + data.result.start.buildingNumber + ", " + data.result.start.city
      }
      let end = {}

      if (data.result.end) {
        end = {
          bNum: data.result.end.buildingNumber,
          city: data.result.end.city,
          lat: data.result.end.lat,
          lng: data.result.end.lon,
          street: data.result.end.street,
          name: data.result.end.street + " " + data.result.end.buildingNumber + ", " + data.result.end.city
        }
      }

      this.setState({
        userList: [user],
        startDate: new Date(data.result.orderTime),
        animals: data.result.animals,
        silentRide: data.result.silentRide,
        langDe: data.result.langDe,
        saveComment: data.result.saveComment,
        estate: data.result.estate,
        hybrid: data.result.hybrid,
        carClass: data.result.carClass,
        orderComment: data.result.orderComment,
        date: "later",
        startSelectedAddress: start,
        endSelectedAddress: end,
        project: { id: data.result.projectId }
      })
    })
  }

  update() {
    this.setState({
      userList: [{
        fellowPassenger: 0,
        phone: "",
        passengerName: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.firstname + " " + this.props.user.lastname : "",
        cash: false,
        voucherId: null,
        businessUserId: this.props.user.userType === "USER" && this.props.match.params.type !== "guest" ? this.props.user.id : null
      }],
      start: "",
      end: "",
      orderComment: "",
      startDate: new Date(),
      map: { lat: 52.237901, lng: 21.036469, zoom: 11 },
      startSuggestions: [],
      endSuggestions: [],
      projects: [],
      projectsArray: [],
      project: {},
      saveComment: false,
      date: "now",
      startSelectedAddress: { name: "" },
      endSelectedAddress: { name: "" },
      carClass: "ECONOMIC",
      estate: false,
      hybrid: false,
      silentRide: false,
      langDe: false,
      filtersPopup: false,
      orderNr: [],
      priceHash: null,
      guaranteedPrice: null,
      guaranteedPriceSelected: false,
    })

    this.getProjects()
  }

  validate(fields) {
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if (document.getElementById(field)) document.getElementById(field).className = "input-error"
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if (first[0]) first[0].focus()
    }
  }

  shortFetch(method, endpoint, data, success) {
    fetch(this._apiBase + endpoint, {
      method: method,
      withCredentials: true,
      credentials: "include",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'X-api-key': '46B9D48A125733B4C9226CE570007',
        'X-business-token': localStorage.getItem("accessToken")
      },
      body: data ? JSON.stringify(data) : null
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.checkSession()
        } else if (response.status === 404 || response.status >= 500) {
          this.props.history.replace("/error/"+response.status+"?redirect="+this.props.location.pathname)
        } else {
          return response.json()
        }
      })
      .then((result) => {
        if (result.answer === "OK") {
          success(result)
        } else if (result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': result.result.errors[i].msg } }), true)
          }
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
        }
        if (result.fieldData.fields && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.loginPage.genericError } }), true)
      }).catch(_err => {
        this.setState({isLoading: false})
        this.setState({isCGWLoading: false})
      }).finally(() => {
        this.setState({isLoading: false})
        this.setState({isCGWLoading: false})
      })
  }

  getProjects() {
    this.shortFetch("GET", "projects/short", null, this.successGetProjects)
  }

  successGetProjects(data) {
    let projects = []
    for (var i = 0; i < Object.entries(data.result.data).length; i++) {
      let item = { id: Object.entries(data.result.data)[i][0], name: Object.entries(data.result.data)[i][1] }
      projects.push(item)
    }
    this.setState({ projects: data.result.data, projectsArray: projects })
  }

  getGuaranteedPrice() {
    let data = {
      projectId: this.state.project.id || null,
      id: this.props.match.params.id || null,
      saveComment: this.state.saveComment,
      orderComment: this.state.orderComment,
      orderTime: this.state.date === "later" ? this.state.startDate.getTime() : this.props.match.params.type === "edit" ? new Date().getTime() : null,
      carClass: this.state.carClass,
      estate: this.state.estate,
      hybrid: this.state.hybrid,
      animals: this.state.animals,
      silentRide: this.state.silentRide,
      langDe: this.state.langDe,
      start: {
        "city": this.state.startSelectedAddress.city,
        "street": this.state.startSelectedAddress.street,
        "buildingNumber": this.state.startSelectedAddress.bNum,
        "lat": this.state.startSelectedAddress.lat,
        "lon": this.state.startSelectedAddress.lng
      },
      end: this.state.endSelectedAddress.lat && this.state.userList.length === 1 ? {
        "city": this.state.endSelectedAddress.city,
        "street": this.state.endSelectedAddress.street,
        "buildingNumber": this.state.endSelectedAddress.bNum,
        "lat": this.state.endSelectedAddress.lat,
        "lon": this.state.endSelectedAddress.lng
      } : null,
      passenger: this.state.userList
    }

    if(this.state.area.pickUpPoints && this.state.area.pickUpPoints.length > 0 && this.state.selectedArea !== -1) {
      data.passengerAreaId = this.state.area.passengerAreaId
      data.pickUpPointId = this.state.area.pickUpPoints[this.state.selectedArea].id
      data.start.street = this.state.area.pickUpPoints[this.state.selectedArea].name + ", " + this.state.area.areaStreet
      data.start.buildingNumber = this.state.area.areaBldNum
      data.start.lat = this.state.area.pickUpPoints[this.state.selectedArea].lat
      data.start.lon = this.state.area.pickUpPoints[this.state.selectedArea].lon
    }

    this.shortFetch("POST", "order/computePrice", data, this.successGetGuaranteedPrice)
  }

  successGetGuaranteedPrice(data) {
    this.setState({ guaranteedPrice: data.result.price, priceHash: data.result.priceHash })
  }

  changeProject(item) {
    this.setState({ project: item })
    this.getPrice()
  }

  changeStartAddress(item) {
    this.setState({ startSelectedAddress: item, selectedArea: -1, area: {} })
    this.getArea(item.lat, item.lng)
    this.getPrice()
  }

  changeEndAddress(item) {
    this.setState({ endSelectedAddress: item })
    this.getPrice()
  }

  handleDateChange(date) {
    this.setState({
      startDate: date
    });
    this.getPrice()
  }

  handleInputChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    this.setState({
      [name]: value
    })
    this.getPrice()
  }

  updateUser(index, user) {
    if (user.businessUserId && this.props.user.userType !== "USER") {
      this.shortFetch("GET", "users/" + user.businessUserId, null, (data) => {
        let list = this.state.userList

        user.vip = data.result.user.vip

        let filters = []
        if(data.result.user.profileId) {
          filters = _.filter(data.result.profiles, { id: data.result.user.profileId })[0]
        }

        if(filters.filters && filters.filters.length > 0) {
          for (let i = 0; i < filters.filters.length; i++) {
            const element = filters.filters[i];
            if(element === 3) {
              this.setState({ estate: true })
            } else if(element === 4) {
              this.setState({ langDe: true })
            } else if(element === 5) {
              this.setState({ hybrid: true })
            }
          }
        }

        list[index] = user

        this.setState({ userList: list })
        this.getPrice()
      })
    } else {
      let list = this.state.userList

      list[index] = user

      this.setState({ userList: list })
      this.getPrice()
    }
  }

  selectUser(index, item) {
    let list = this.state.userList
    let value = item.name
    let name = "name"

    list[index][name] = value

    if (list[index].name !== "") {
      list[index].filled = true
    } else {
      list[index].filled = false
    }

    if (item.limit) {
      list[index].limit = item.limit
    }

    if (item.vip) {
      list[index].vip = item.vip
    }

    list[index].selected = true

    this.setState({ userList: list })
    this.getPrice()
  }

  deleteUser(index) {
    let list = this.state.userList
    list.splice(index, 1)
    this.setState({ userList: list })
  }

  addUser() {
    let list = this.state.userList
    let item = {
      fellowPassenger: 0,
      phone: "",
      passengerName: "",
      cash: false,
      voucherId: null,
      businessUserId: null
    }
    list.push(item)
    this.setState({ userList: list })
    this.getPrice()
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((e) => {
        this.shortFetch("GET", "location?lat=" + e.coords.latitude + "&lon=" + e.coords.longitude, null, (data) => {
          this.setState({ startSuggestions: data.result.results, startSelectedAddress: data.result.results[0] })
          //setTimeout(() => { document.getElementById('startSelect').click() }, 200)
        })
      })
    }
  }

  getArea(lat, lng) {
    this.setState({ selectedArea: -1 })
    if(lat && lng) {
      this.shortFetch("GET", "location/area?lat=" + lat + "&lon=" + lng, null, (data) => {
        let area = data.result
        this.setState({ area: area })
        if(area.pickUpPoints.length === 1) {
          this.setState({ selectedArea: 0 })
        }
      })
    } else {
      this.setState({ area: {} })
    }
  }

  fetchSuggestionsStart(suggest) {
    suggest = encodeURIComponent(suggest)
    this.shortFetch("GET", "location/suggest?phrase=" + suggest + "&limit=10", null, this.fetchSuggestionsSuccessStart)
  }

  fetchSuggestionsSuccessStart(data) {
    this.setState({ startSuggestions: data.result.results })
    // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pobrano propozycje lokalizacji" }}), true)
  }

  fetchSuggestionsEnd(suggest) {
    suggest = encodeURIComponent(suggest)
    this.shortFetch("GET", "location/suggest?phrase=" + suggest + "&limit=10", null, this.fetchSuggestionsSuccessEnd)
  }

  fetchSuggestionsSuccessEnd(data) {
    this.setState({ endSuggestions: data.result.results })
    // window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pobrano propozycje lokalizacji" }}), true)
  }

  makeOrder(guaranteedP) {
    if (this.props.match.params.type === "edit") {
      if (this.state.more) {
        window.gtag('event', "edytuje_chce_wiecejopcji")
      }

      if (this.state.addAnother) {
        window.gtag('event', "edytuje_chce_kolejnataxi")
      }

      if (this.state.date === "later" ){
        if (this.state.startDate < this.nearestHalfHour()){
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.order.inAdvance } }), true)
          return;
        }
        window.gtag('event', "edytuje_chce_napotem")
      } else {
        window.gtag('event', "edytuje_chce_nateraz")
      }

      if (this.state.project.id) {
        window.gtag('event', "edytuje_chce_projekt")
      }

      if (this.state.orderComment) {
        window.gtag('event', "edytuje_chce_komentarz")
      }

      if (this.state.endSelectedAddress.lat) {
        window.gtag('event', "edytuje_wpisany_docelowy")
      } else {
        window.gtag('event', "edytuje_niewpisany_docelowy")
      }

      if (this.state.carClass === "ECONOMIC") {
        window.gtag('event', "edytuje_chce_popularna")
      } else {
        window.gtag('event', "edytuje_chce_luksusowa")
      }

      if (this.state.estate) {
        window.gtag('event', "edytuje_chce_kombi")
      }

      if (this.state.hybrid) {
        window.gtag('event', "edytuje_chce_hybryde")
      }

      if (this.state.silentRide) {
        window.gtag('event', "edytuje_chce_cichyprzejazd")
      }

      if (this.state.langDe) {
        window.gtag('event', "edytuje_chce_zakupy")
      }

      if (this.state.oplaty) {
        window.gtag('event', "edytuje_sprawdzaoplaty")
      }
    } else {
      if (this.state.more) {
        window.gtag('event', "zamawia_chce_wiecejopcji")
      }

      if (this.state.addAnother) {
        window.gtag('event', "zamawia_chce_kolejnataxi")
      }

      if (this.state.date === "later" ){
        if (this.state.startDate < this.nearestHalfHour()){
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': { 'type': "red", 'content': this.props.data.order.inAdvance } }), true)
          return;
        }
        window.gtag('event', "zamawia_chce_napotem")
      } else {
        window.gtag('event', "zamawia_chce_nateraz")
      }

      if (this.state.project.id) {
        window.gtag('event', "zamawia_chce_projekt")
      }

      if (this.state.orderComment) {
        window.gtag('event', "zamawia_chce_komentarz")
      }

      if (this.state.endSelectedAddress.lat) {
        window.gtag('event', "zamawia_wpisany_docelowy")
      } else {
        window.gtag('event', "zamawia_niewpisany_docelowy")
      }

      if (this.state.carClass === "ECONOMIC") {
        window.gtag('event', "zamawia_chce_popularna")
      } else {
        window.gtag('event', "zamawia_chce_luksusowa")
      }

      if (this.state.estate) {
        window.gtag('event', "zamawia_chce_kombi")
      }

      if (this.state.hybrid) {
        window.gtag('event', "zamawia_chce_hybryde")
      }

      if (this.state.silentRide) {
        window.gtag('event', "zamawia_chce_cichyprzejazd")
      }

      if (this.state.langDe) {
        window.gtag('event', "zamawia_chce_zakupy")
      }

      if (this.state.oplaty) {
        window.gtag('event', "zamawia_sprawdzaoplaty")
      }
    }

    let data = {
      projectId: this.state.project.id || null,
      id: this.props.match.params.id || null,
      saveComment: this.state.saveComment,
      orderComment: this.state.orderComment,
      orderTime: this.state.date === "later" ? this.state.startDate.getTime() : this.props.match.params.type === "edit" ? new Date().getTime() : null,
      carClass: this.state.carClass,
      estate: this.state.estate,
      hybrid: this.state.hybrid,
      animals: this.state.animals,
      silentRide: this.state.silentRide,
      langDe: this.state.langDe,
      start: {
        "city": this.state.startSelectedAddress.city,
        "street": this.state.startSelectedAddress.street,
        "buildingNumber": this.state.startSelectedAddress.bNum,
        "lat": this.state.startSelectedAddress.lat,
        "lon": this.state.startSelectedAddress.lng
      },
      end: this.state.endSelectedAddress.lat && this.state.userList.length === 1 ? {
        "city": this.state.endSelectedAddress.city,
        "street": this.state.endSelectedAddress.street,
        "buildingNumber": this.state.endSelectedAddress.bNum,
        "lat": this.state.endSelectedAddress.lat,
        "lon": this.state.endSelectedAddress.lng
      } : null,
      passenger: this.state.userList
    }

    if(guaranteedP && this.state.priceHash) {
      data.guaranteedPriceHash = this.state.priceHash
    }

    if(this.state.area.pickUpPoints && this.state.area.pickUpPoints.length > 0 && this.state.selectedArea !== -1) {
      data.passengerAreaId = this.state.area.passengerAreaId
      data.pickUpPointId = this.state.area.pickUpPoints[this.state.selectedArea].id
      data.start.street = this.state.area.pickUpPoints[this.state.selectedArea].name + ", " + this.state.area.areaStreet
      data.start.buildingNumber = this.state.area.areaBldNum
      data.start.lat = this.state.area.pickUpPoints[this.state.selectedArea].lat
      data.start.lon = this.state.area.pickUpPoints[this.state.selectedArea].lon
    }

    if (this.props.match.params.type === "edit") {
      this.shortFetch("POST", "order/edit", data, (data) => {
        this.setState({ successPopup: true })
        window.gtag('event', "zamowione_zmien_potwierdza")
        this.props.history.replace(this.props.location.pathname+"#success")
      }).then(()=> {
      })
    } else {
      if (!guaranteedP) {
        console.log(1)
        this.setState({ isLoading: true })
      } else {
        this.setState({ isCGWLoading: true })
      }
      this.shortFetch("POST", "order/make", data, (data) => {
        this.setState({ successPopup: true, orderNr: data.result.orders })
        window.gtag('event', "zamawia_sukces")
        this.props.history.replace(this.props.location.pathname+"#success")
      })
    }
  }

  validatePhone(phone) {

    //eslint-disable-next-line
    const reg = /[+\- \(\)0-9]*/g;
    if (reg.test(phone)) {
      return true
    } else {
      return false
    }
  }

  resetStartAddress() {
    this.setState({ startSelectedAddress: { name: "" } })
  }

  render() {
    const page = this.props.data.order
    const position = [this.state.map.lat, this.state.map.lng]

    const userList = this.state.userList.map((item, i) => {
      return (
        <UserComponent selectedUser={this.state.userList[i]} user={this.props.user} data={this.props.data} key={i} item={item} i={i} updateUser={this.updateUser} selectUser={this.selectUser} deleteUser={this.deleteUser} users={this.users} {...this.props} setIsGenerateVoucherButtonDisabled={this.setIsGenerateVoucherButtonDisabled} isGenerateVoucherButtonDisabled={this.state.isGenerateVoucherButtonDisabled} setProjectFromVoucher={this.changeProject} setIsProjectInputDisabled={this.setIsProjectInputDisabled}/>
      )
    })

    const summary = _.filter(this.state.userList, (item) => {
      return item.businessUserId !== undefined && item.businessUserId !== null
    }).map((item, i) => {
      return (
        <li key={i} className="item">
          <h3><small>{ page.taxi } {i + 1}</small>{item.passengerName}</h3>
          <ul>
            {item.limit ? <li>{ page.mainLimit } {item.limit} { page.pln }</li> : null}
            {item.vip ? <li>{ page.vip }</li> : null}
          </ul>
        </li>
      )
    })

    let showAdditionalPayment = false
    let additionalPaymentValue = 0

    if (this.state.startSelectedAddress && this.state.startSelectedAddress.city) {
      if (this.state.startSelectedAddress.city.indexOf("Warszawa") !== -1) {
        additionalPaymentValue = "40.00" + (this.props.lang === "pl" ? "zł" : "PLN")
        showAdditionalPayment = true
      } else if (this.state.startSelectedAddress.city.indexOf("Katowice") !== -1) {
        additionalPaymentValue = "15.00" + (this.props.lang === "pl" ? "zł" : "PLN")
        showAdditionalPayment = true
      } else if (this.state.startSelectedAddress.city.indexOf("Poznań") !== -1 || this.state.startSelectedAddress.city.indexOf("Gdynia") !== -1 || this.state.startSelectedAddress.city.indexOf("Gdańsk") !== -1 || this.state.startSelectedAddress.city.indexOf("Sopot") !== -1) {
        additionalPaymentValue = "20.00" + (this.props.lang === "pl" ? "zł" : "PLN")
        showAdditionalPayment = true
      } else if (this.state.startSelectedAddress.city.indexOf("Kraków") !== -1 || this.state.startSelectedAddress.city.indexOf("Łódź") !== -1 || this.state.startSelectedAddress.city.indexOf("Lublin") !== -1 || this.state.startSelectedAddress.city.indexOf("Rzeszów") !== -1) {
        additionalPaymentValue = "0.00" + (this.props.lang === "pl" ? "zł" : "PLN")
        showAdditionalPayment = false
      } else {
        additionalPaymentValue = "10.00" + (this.props.lang === "pl" ? "zł" : "PLN")
        showAdditionalPayment = true
      }
    } else {
      showAdditionalPayment = false
    }

    let Popup = (
      <div className="password-modal">
        <div className="inputs">
          <h3>{ page.options }</h3>
        </div>
        <div className="inputs">
          <div className="car-class input">
            <div className={this.state.carClass === "ECONOMIC" ? "cclass active" : "cclass"} onClick={() => { this.setState({ carClass: "ECONOMIC" }); this.getPrice() }}>
              <div className="icon economic"><img src="/assets/img/economic.png" alt="Economic" /></div>
              <p>{ page.popular }</p>
            </div>
            <div className={this.state.carClass === "PREMIUM" ? "cclass active" : "cclass"} onClick={() => { this.setState({ carClass: "PREMIUM" }); this.getPrice() }}>
              <div className="icon"><img src="/assets/img/premium.png" alt="Premium" /></div>
              <p>{ page.premium }</p>
            </div>
          </div>
          { this.props.user.extraFilter && this.props.match.params.type === "guest" ? <div className="input">
            <div className="check-box"><input name="langDe" id="cb5" type="checkbox" checked={this.state.langDe} onChange={this.handleInputChange} /><label htmlFor="cb5">{ page.shopping }</label></div>
          </div> : null }
          <div className="input">
            <div className="check-box"><input name="estate" id="cb4" type="checkbox" checked={this.state.estate} onChange={this.handleInputChange} /><label htmlFor="cb4">{ page.estate }</label></div>
          </div>
          <div className="input">
            <div className="check-box"><input name="silentRide" id="cb3" type="checkbox" checked={this.state.silentRide} onChange={this.handleInputChange} /><label htmlFor="cb3">{ page.silent }</label></div>
          </div>
          <div className="input">
            <div className="check-box"><input name="hybrid" id="cb7" type="checkbox" checked={this.state.hybrid} onChange={this.handleInputChange} /><label htmlFor="cb7">{ page.hybrid }</label></div>
          </div>
          <p className="additional-info-box">{ page.other } <a href="mailto:zamowienia@itaxi.pl">zamowienia@itaxi.pl </a> { page.otherOr } <a href="tel:737 737 737">737 737 737</a></p>
          <a className="more" href="https://itaxi.pl/cennik_biznesowy.pdf" target="_blank" rel="noopener noreferrer" onClick={ () => { this.setState({ oplaty: true }) } }>{ page.fee }</a>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={() => { this.setState({ filtersPopup: false }) }}><span>{ page.select }</span></button>
        </div>
      </div>
    )

    let successPopup = (
      <div className="password-modal success-order">
        <div className="inputs">
          {this.props.match.params.type === "edit" ? <h3>{ page.orderUpdated }</h3> : <h3>{ page.orderSuccess }</h3>}
        </div>
        <div className="inputs">
          <p><strong>{ page.details }</strong></p>
          <ul>
            {this.props.match.params.type !== "edit" ? <li>{ page.taxiNo } <strong>{this.state.orderNr.length}</strong></li> : null}
            <li>{this.state.userList.length > 1 ? page.passengers2 : page.passenger}<strong>{this.state.userList.map((item, i) => { return <span key={i}>{item.passengerName}{this.state.userList.length > 1 && i < this.state.userList.length-1 ? ", " : ""}</span> })}</strong></li>
            <li>{ page.startAddress } <strong>{this.state.startSelectedAddress.name}</strong></li>
            {this.state.endSelectedAddress.name && this.state.userList.length === 1 ? <li>{ page.destAddress } <strong>{this.state.endSelectedAddress.name}</strong></li> : null}
            <li>{ page.when } <strong>{this.state.date === "now" ? page.now : this.parseDate(this.state.startDate, true, false)}</strong></li>
            {this.state.orderComment ? <li>{ page.comment } <strong>{this.state.orderComment}</strong></li> : null}
            {this.state.guaranteedPriceSelected && this.state.guaranteedPrice ? <li>{ page.guarantedLabel + this.state.guaranteedPrice + page.guarantedLabelCurrency }</li> : <li>{page.taximeterLabel}</li>}
          </ul>
        </div>
        <div className="buttons">
          <button className="btn yellow small" id="button" onClick={() => { this.setState({ successPopup: false, guaranteedPriceSelected: false }); window.dispatchEvent(new CustomEvent("UPDATE", { 'detail': {} }), true) }}><span>OK</span></button>
        </div>
      </div>
    )

    const isToday = (someDate) => {
      const today = new Date()
      if (someDate) {
        return someDate.getDate() === today.getDate() &&
          someDate.getMonth() === today.getMonth() &&
          someDate.getFullYear() === today.getFullYear()
      } else {
        return false
      }
    }

    let orderFromTime = new Date();
    if (isToday(this.state.startDate)) {
      orderFromTime.setMinutes(orderFromTime.getMinutes() + 30);
    } else {
      orderFromTime.setHours(0, 0, 0, 0);
    }

    let orderToTime = new Date();
    orderToTime.setHours(23, 59, 59, 999);

    const areaIcon = new Leaflet.Icon({
      iconUrl: "/assets/img/marker-blue.svg",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Leaflet.Point(40, 50),
      className: 'marker-area'
    });

    const areaIconSelected = new Leaflet.Icon({
      iconUrl: "/assets/img/marker-dark-blue.svg",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Leaflet.Point(40, 50),
      className: 'marker-area'
    });

    const taxiIcon = new Leaflet.Icon({
      iconUrl: "/assets/img/marker.svg",
      iconAnchor: null,
      popupAnchor: null,
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new Leaflet.Point(40, 50),
      className: 'marker-taxi'
    });

    return (
      <section className="order-page">
        { this.state.area.surcharge ? <div className="main-notification" style={{ margin: 0, marginBottom: "20px" }}><p dangerouslySetInnerHTML={{ __html: this.state.area.surcharge }}></p></div> : null }
        <div className="box">
          <div className="order-inner">
            <div className="basic-info">
              <div className="user-data">
                <div className="order-person">
                  {userList}
                  {this.props.user.userType === "USER" || this.props.match.params.type === "edit" ? null : <div className="add" onClick={ () => { this.addUser(); this.setState({ addAnother: true }) } }><span>+</span>{ page.addAnother }</div>}
                </div>
                <div className="destination">
                  <div className="destination-basic">
                  <div className="input start">
                      <label>{ page.startAddress }*</label>
                      <Select noResults={ this.props.data.table.noResults } data={this.state.startSuggestions} selectedAddress={this.state.startSelectedAddress} filters={['name']} placeholder={this.props.data.manage.type} onChange={this.getSuggestionsStart} changeValue={this.changeStartAddress} id="buildingNumber" />
                      <div className="location" onClick={this.getLocation}><img src="/assets/img/loc.png" alt="" /></div>
                    </div>
                    { this.state.area.pickUpPoints && this.state.area.pickUpPoints.length > 0 ? <div className="input start">
                      <label>{ page.pickUp }*</label>
                      <Select noResults={ this.props.data.table.noResults } data={this.state.area.pickUpPoints} selectedAddress={this.state.area.pickUpPoints[this.state.selectedArea]} filters={['name']} placeholder={this.props.data.manage.type} onChange={(data) => {  }} changeValue={(data) => { this.setState({ selectedArea: this.state.area.pickUpPoints.indexOf(data) }) }} id="passengerAreaId" />
                    </div> : null }
                    {this.state.userList.length > 1 ? null : <div className="input">
                      <label>{ page.destAddress }</label>
                      <Select noResults={ this.props.data.table.noResults } data={this.state.endSuggestions} selectedAddress={this.state.endSelectedAddress} filters={['name']} placeholder={this.props.data.manage.type} onChange={this.getSuggestionsEnd} changeValue={this.changeEndAddress} />
                    </div>}
                  </div>
                  <div className="destination-map">
                    <Map center={this.state.startSelectedAddress.lat && !this.state.endSelectedAddress.lat ? [this.state.startSelectedAddress.lat, this.state.startSelectedAddress.lng] : position} zoom={this.state.map.zoom} animate={true} bounds={this.state.startSelectedAddress.lat && this.state.endSelectedAddress.lat ? Leaflet.latLngBounds([[this.state.startSelectedAddress.lat, this.state.startSelectedAddress.lng], [this.state.endSelectedAddress.lat, this.state.endSelectedAddress.lng]]) : this.state.startSelectedAddress.lat ? Leaflet.latLngBounds([[this.state.startSelectedAddress.lat, this.state.startSelectedAddress.lng], [this.state.startSelectedAddress.lat + 0.01, this.state.startSelectedAddress.lng + 0.01]]) : this.state.endSelectedAddress.lat ? Leaflet.latLngBounds([[this.state.endSelectedAddress.lat, this.state.endSelectedAddress.lng], [this.state.endSelectedAddress.lat + 0.01, this.state.endSelectedAddress.lng + 0.01]]) : null}>
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {this.state.startSelectedAddress.lat ? <Marker icon={ taxiIcon } position={[this.state.startSelectedAddress.lat, this.state.startSelectedAddress.lng]}></Marker> : null}
                      {this.state.endSelectedAddress.lat ? <Marker icon={ taxiIcon } position={[this.state.endSelectedAddress.lat, this.state.endSelectedAddress.lng]}></Marker> : null}
                      { this.state.area.pickUpPoints && this.state.area.pickUpPoints.length > 0 ? this.state.area.pickUpPoints.map((item, i) => {
                        return ( <Marker key={i} position={[item.lat, item.lon]} icon={ this.state.selectedArea === i ? areaIconSelected : areaIcon } onClick={ () => { this.setState({ selectedArea: i }) } }>
                          <Tooltip direction='bottom' offset={[0, 20]} opacity={1} permanent>
                                <span>{item.name}</span>
                          </Tooltip>
                        </Marker> )
                      }) : null }
                    </Map>
                  </div>
                </div>
              </div>
              <div className="summary">
                <ul className="summary-list">
                  {summary}
                </ul>
              </div>
            </div>
            <div className="additional-info">
              <div className="pricing-date">
                <label>{ page.when }</label>
                <div className="input">
                  <div className="select">
                    { this.props.match.params.type === "edit" ? null : <input type="radio" checked={this.state.date === "now"} name="date" id="date1" value="now" onChange={this.handleInputChange} /> }
                    { this.props.match.params.type === "edit" ? null : <label htmlFor="date1">{ page.now }</label> }
                    <input type="radio" checked={this.state.date === "later"} name="date" id="date2" value="later" onChange={this.handleInputChange} />
                    <label htmlFor="date2" className="select-date">{ page.later } <DatePicker
                      selected={this.state.startDate}
                      onChange={this.handleDateChange}
                      showTimeSelect
                      locale="pl"
                      minDate={new Date()}
                      minTime={orderFromTime}
                      maxTime={orderToTime}
                      timeIntervals="10"
                      dateFormat="dd.MM.yyyy, HH:mm"
                      withPortal={this.props.screen.width > 1010 ? false : true}
                      timeCaption="Godz."
                    /></label>
                  </div>
                </div>
                <span className="more" onClick={() => { this.setState({ filtersPopup: true, more: true }) }}>{ page.moreOptions }</span>
              </div>
              <div className="project">
                <div className="input">
                  <label className={this.state.isProjectInputDisabled ? 'disabled' : null}>{ page.project }{JSON.parse(localStorage.getItem("projectRequired")) ? !this.state.isGenerateVoucherButtonDisabled && "*" : ''}</label>
                  <Select isProjectInputDisabled={this.state.isProjectInputDisabled} noResults={ this.props.data.table.noResults } selected={this.state.project.id} data={this.state.projectsArray} filters={['name']} placeholder={this.props.data.manage.type} changeValue={this.changeProject} />
                </div>
                <div className="input">
                  <label>{ page.addComment }</label>
                  <textarea name="orderComment" value={this.state.orderComment} onChange={this.handleInputChange} placeholder={ page.commentTextarea }></textarea>
                </div>
                <div className="input hidden">
                  <div className="check-box"><input name="saveComment" id="cb1" type="checkbox" checked={this.state.saveComment} onChange={this.handleInputChange} /><label htmlFor="cb1">{ page.saveComment }</label></div>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            { this.props.match.params.type !== "edit" && <button disabled={this.state.isCGWLoading} className={this.state.priceHash ? "btn green" : "btn green disabled"} onClick={() => { if(this.state.priceHash) { this.setState({guaranteedPriceSelected: true}); this.makeOrder(true) } }}><span>{page.orderNowGuaranteedPrice}{this.state.priceHash ? this.state.guaranteedPrice + page.guarantedLabelCurrency : page.orderNowUnavailable}</span>{this.state.isCGWLoading && (<div style={{float: "right", marginTop: "10px", marginLeft: "10px"}}><img width="35" height="35" className="buttton-loader" src="/assets/img/loader.svg" alt="Loading...."/></div>)}</button> }<br/><br/>
            <button disabled={this.state.isLoading} className={this.state.isLoading ? "btn green disabled" : "btn yellow"} onClick={() => { this.setState({guaranteedPriceSelected: false}); this.makeOrder(false) }}><span>{this.props.match.params.type === "edit" ? page.save : page.orderNow}</span>{this.state.isLoading &&(<div style={{float: "right", marginTop: "10px", marginLeft: "10px"}}><img width="35" height="35" className="buttton-loader" src="/assets/img/loader.svg" alt="Loading...."/></div>)}</button>
            <p className="access">{ page.anyProblems } <a href="mailto:cob@itaxi.pl">cob@itaxi.pl</a></p>
          </div>
        </div>
        <Modal open={this.state.filtersPopup} hidePopup={() => { this.setState({ filtersPopup: false }); setTimeout(() => { this.setState({ action: "" }) }, 500) }}>{Popup}</Modal>
        <Modal open={this.state.successPopup} hidePopup={() => { this.setState({ successPopup: false, guaranteedPriceSelected: false }); setTimeout(() => { this.setState({ action: "" }); window.dispatchEvent(new CustomEvent("UPDATE", { 'detail': {} }), true) }, 500) }}>{successPopup}</Modal>
      </section>
    )
  }
}
